.Contact__method-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-family: var(--font-monospace);
  margin-bottom: 16px;
}
.Contact__method-icon {
  font-size: 1.25rem;
  color: var(--color-white);
}
.Contact__method {
  margin-bottom: 0;
  line-height: normal;
}
.Contact__fine-print {
  font-size: 0.75em;
  border-left: 1px solid;
  border-color: var(--section-item-desc-border-color);
  padding-left: 1rem;
  margin-top: 48px;
}
