@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

@import '~@csstools/normalize.css';

:root {
  --color-white: rgb(255, 255, 255);
  --color-black: rgb(0, 0, 0);
  --color-dark-grey: rgb(10, 10, 10);
  --color-light-grey: rgb(20, 20, 20);
  --color-transparent-black: rgba(0, 0, 0, 0.85);
  --color-accent-1: rgb(0, 255, 255);
  --color-accent-2: rgb(255, 0, 255);
  --color-accent-3: rgb(106, 0, 255);
  --color-accent-4: rgb(106, 0, 255);
  --color-section-title: rgb(0, 0, 0);

  --body-bg-color: var(--color-black);

  --font-monospace: 'Source Code Pro', monospace;
  --font-title: Arial, Helvetica, sans-serif;
  --font-color: var(--color-white);

  --section-bg-color: var(--color-black);
  --section-heading-gradient: linear-gradient(
    15deg,
    var(--color-accent-1),
    var(--color-white)
  );
  --section-item-desc-border-color: rgb(255, 255, 255);
  --section-glow-color-1: var(--color-accent-1);
  --section-glow-color-2: var(--color-accent-3);
  --section-glow-color-3: var(--color-accent-2);
  --section-glow-color-4: var(--color-accent-4);

  --grid-border-color: var(--color-white);
  --grid-border-glow-color: var(--color-accent-1);

  --name-tag-border-color: var(--color-white);
  --name-tag-bg-color: var(--color-black);
  --name-tag-middle-color: var(--color-white);

  --matrix-perpective: min(17vw, 150px);
  --matrix-space-between-grids: 2px;
  --matrix-grid-x-rotation: 45deg;

  --lights-off-speed-light-bulb: 100ms;
  --lights-off-speed-body: 250ms;

  --mobile-toggle-border-color: var(--color-white);
  --mobile-toggle-speed: 200ms;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-monospace);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg-color);
  color: var(--font-color);
  overflow-x: hidden;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: var(--font-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
p {
  margin-top: 0;
  line-height: 1.5;
}
