/* @property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
} */
/* for rotation gradient */

.Section__wrapper {
  min-height: 100svh;
  padding-top: min(8vw + 32px, 5rem);
  padding-bottom: 60px;
  display: flex;
  align-items: center;
}

.Section__container {
  padding: min(7% + 16px, 5rem) 7%;
  width: min(900px, 85%);
  margin-inline: auto;
  position: relative;
  /* set font size here and use em's in the children so we can control all scaling from here! */
  /* as a baseline, all "descriptions" are 1em (the same size as the font size being set here */
  /* intro should be different, so check */
  font-size: 1rem;
}

.Section__container > *:last-child {
  margin-bottom: 0;
}

/* intro section */
.Section__intro-wrapper {
  height: 100svh;
  overflow: hidden;
}
.Section__intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 1000px;
}

/* about section */
.Section__about-container.Section__container-glow {
  background-color: var(--section-bg-color);
}

/* projects section */
.Section__projects-container.Section__container-glow {
  background-color: var(--section-bg-color);
}

/* contact section */

.Section__contact-container.Section__container-glow {
  background-color: var(--section-bg-color);
}

/* container glow*/
@keyframes glow-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.Section__container-glow::before,
.Section__container-glow::after {
  content: '';
  position: absolute;
  inset: -1px;
  z-index: -1;
  background: conic-gradient(
    from -45deg,
    var(--section-glow-color-3),
    var(--section-glow-color-2),
    var(--section-glow-color-4),
    var(--section-glow-color-1),
    var(--section-glow-color-4),
    var(--section-glow-color-2),
    var(--section-glow-color-3)
  );
  border-radius: inherit;
  animation: glow-pulse 5s linear infinite;
  opacity: 1;
  transition: opacity var(--lights-off-speed-body) ease-out;
}
.Section__container-glow::after {
  filter: blur(1rem);
}

.Section__heading {
  display: inline-block;
  margin-bottom: 2rem;
  color: var(--color-white);
  font-family: var(--font-title);
  font-size: clamp(1.5rem, 7vw, 3.5rem);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.1em;
  /* background-clip: text; */
}

.Section__heading-blurb {
  margin-bottom: 48px;
  font-size: 0.9em;
}

.Section__content-container {
  margin-bottom: 64px;
}
.Section__content-container > *:last-child {
  margin-bottom: 0;
}
.Section__content-title {
  font-family: var(--font-monospace);
  margin-bottom: 32px;
  font-size: 1em;
}
.Section__content-title::before {
  content: '=> ';
  color: var(--color-accent-1);
}
@keyframes cursor-blink {
  50% {
    opacity: 1;
  }
}
/* .Section__content-title::after {
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.8em;
  margin-left: 0.15em;
  margin-bottom: -2px;
  background-color: var(--color-white);
  opacity: 0;
  animation: cursor-blink 1000ms steps(1) infinite;
} */
.Section__content-item-container {
  margin-bottom: 32px;
}
.Section__content-item-title {
  margin-bottom: 0.2em;
  font-size: 1em;
}
.Section__content-item-title--no-date {
  margin-bottom: 0.8em;
}
.Section__content-item-title-link {
  padding-left: 10px;
}
.Section__content-item-title-link-icon {
  /* this is relative to the About__content-item-title */
  font-size: 0.8em;
}
.Section__content-item-subtitle {
  margin-bottom: 0.2em;
  font-size: 0.9em;
}
.Section__content-item-date {
  font-size: 0.9em;
  margin-bottom: 0.8em;
}
.Section__content-item-desc {
  font-size: 0.85em;
}
@media screen and (min-width: 768px) {
  .Section__heading-blurb {
    font-size: 1em;
  }
  .Section__content-title {
    font-size: 1.5em;
  }
  .Section__content-item-title {
    font-size: 1.2em;
  }
  .Section__content-item-desc {
    font-size: 1em;
    border-left: 1px solid;
    border-color: var(--section-item-desc-border-color);
    padding-left: 1em;
  }
}
