/* .Projects__projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: row;
  gap: 32px;
} */

.el {
  opacity: 0;
  transition: all 1s;
}
.in-view {
  opacity: 1;
}
@media (prefers-reduced-motion) {
  .el {
    transition: none;
  }
}
