.Footer__container {
  font-family: 'Source Code Pro', monospace;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10;
}

/* the mixed padding and margin's are trick to hide the email address when its "closed" */
.Footer__email {
  padding-left: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  display: flex;
}
.Footer__email-button {
  cursor: pointer;
  background: none;
  border: 1px solid #fff;
  border: none;
  font-size: 1.5rem;
  line-height: 0.8;
  padding: 0;
  margin-bottom: -3px;
}
.Footer__email-address-container {
  overflow-x: hidden;
  margin-top: 1px;
  padding: 1px 0.25rem 0;
}

.Footer__email-address {
  transition: all 200ms ease-out;
  transform: translateX(calc(-100% - 0.25rem));
}

.Footer__email-address.Footer__email-address--show {
  transform: translateX(0%);
}

.Footer__copyright,
.Footer__email-address {
  font-size: 0.8rem;
  line-height: 1;
}

.Footer__copyright {
  padding: 1rem;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: 100% 100%;
}
