.Header {
  font-family: 'Source Code Pro', monospace;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 10;
}
.Header__logo-container {
  transform: translateY(-100%) rotate(90deg);
  transform-origin: 0 100%;
  /* border-top: 1px dotted;
    border-right: 1px dotted;
    border-color: var(--color-white); */
  display: flex;
  justify-content: center;
}
.Header__logo {
  padding: 1rem;
  /* padding right should match the size of the ::after */
  /* padding-right: calc(48px + 1rem); */
  display: inline-block;
}
.Header__logo-link {
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  display: block;
  position: relative;
}
/* .Header__logo-link::after {
  position: absolute;
  content: "";
  width: 48px;
  bottom: 0px;
  border-bottom: 1px solid var(--font-color);
} */
.Header__logo-link:hover {
  color: var(--color-accent-1);
}
.Header__nav-button--lights-desktop {
  transform: rotate(-90deg);
  display: none;
}
.Header__nav-container {
  position: relative;
}

.Header__nav-buttons-container {
  /* padding: 1rem 0.7rem; */
  display: flex;
}
.Header__nav-button {
  cursor: pointer;
  background: none;
  border: 1px solid #fff;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  padding: 1rem;
}
.Header__nav-button--menu {
  padding-left: 0;
}

/* mobile menu toggle */
.Header__nav-mobile-toggle {
  padding-left: 0;
  width: 1.3rem;
  position: relative;
  border-top: 1px solid var(--mobile-toggle-border-color);
  transition: border-color var(--mobile-toggle-speed) ease-in-out;
}
.Header__nav-mobile-toggle::before,
.Header__nav-mobile-toggle::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  transition: all var(--mobile-toggle-speed) ease-out;
}
.Header__nav-mobile-toggle::before {
  border-top: 1px solid var(--mobile-toggle-border-color);
  top: -7px;
}
.Header__nav-mobile-toggle::after {
  border-bottom: 1px solid var(--mobile-toggle-border-color);
  bottom: -6px;
}
/* mobile menu toggle open state */
.Header__nav-mobile-toggle--open {
  border-color: var(--section-bg-color);
}
.Header__nav-mobile-toggle--open::before {
  border-top: 1px solid var(--mobile-toggle-border-color);
  transform: rotate(-45deg);
  top: 0;
}
.Header__nav-mobile-toggle--open::after {
  border-bottom: 1px solid var(--mobile-toggle-border-color);
  transform: rotate(45deg);
  bottom: -1px;
}

.Header__nav-button--lights {
  /* padding: 1rem 0.7rem; */
  transition: text-shadow var(--lights-off-speed-light-bulb) ease-out;
}
.Header__nav-button--lights-on {
  text-shadow: 0px 0px 1px var(--color-accent-1),
    0px 0px 16px var(--color-white);
}

.Header__nav-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  border-left: 1px dotted #fff;
  border-top: 1px dotted #fff;
  border-bottom: 1px dotted #fff;
  background-color: var(--color-transparent-black);
  left: 100%;
  transition: transform var(--mobile-toggle-speed) ease-out;
}
.Header__nav-menu--open {
  transform: translateX(-100%);
}

.Header__nav-link {
  display: block;
  /* left and right padding should match the psuedo elements */
  /* padding: 0 1rem; */
  padding: 1rem;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  /* we give each "a" tag a display property to remove the small gap underneath the inline elements */
}

.Header__nav-link::before,
.Header__nav-link::after {
  /* position: absolute; */
  /* content: ""; */
  /* width: 16px; */
  /* bottom: 0; */
  /* calc our height to include the bottom placement (or bottom margin) */
  /* height: calc(100% - 5px); */
  /* border-bottom: 1px solid var(--font-color); */
}
/* .Header__nav-link::before {
  left: 0;
} */

.Header__nav-link:hover {
  color: var(--color-accent-1);
}

@media screen and (min-width: 768px) {
  .Header__nav-menu {
    background-color: transparent;
  }
  .Header__nav-button--lights-desktop {
    display: inline-block;
    padding: 0;
  }
  .Header__nav-buttons-container {
    display: none;
  }
  .Header__nav-menu {
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    border: none;
    left: initial;
    transition: none;
    padding: 0; /* reset from mobile menu padding */
  }
  .Header__nav-menu > *:first-child > a {
    margin-left: 0.5rem;
  }
  .Header__nav-menu > *:last-child > a {
    margin-right: 0.5rem;
  }
  .Header__nav-menu--open {
    transform: none;
  }
  .Header__nav-link {
    padding: 1rem 0.5rem;
  }
}
