/* below rules are for the grid */
.grid__container {
  display: grid;
  width: 100%;
}

/* defining our grid item size [start/end] */
.grid__child--overlapping {
  grid-column: 1/2;
  grid-row: 1/2;
}

.Intro__hello-sticker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.Intro__hello-sticker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  width: min(400px, 100%);
  border: 1px solid;
  border-color: var(--name-tag-border-color);
  border-radius: 12px 12px 12px 12px;
  text-align: center;
  background-color: var(--name-tag-bg-color);
  transform: rotate(-5deg);
}
.Intro__hello-sticker > * {
  width: 100%;
  padding: 7px;
}

.Intro__hello-sticker--top {
  text-transform: uppercase;
}
.Intro__hello-sticker--middle {
  flex-grow: 1;
  flex-shrink: 0;
  background-color: var(--name-tag-middle-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 48px;
}
.Intro__hello-sticker--bottom {
  flex-shrink: 0;
}

.Intro__preheading-main {
  margin-bottom: 0;
  font-family: var(--font-title);
  font-size: clamp(2rem, 5vw, 2.4rem);
  font-weight: bold;
  line-height: 1;
  letter-spacing: -2px;
}
.Intro__preheading-sub {
  margin-bottom: 0;
  font-family: var(--font-title);
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  line-height: 1;
}
.Intro__heading {
  margin-bottom: 0;
  font-family: var(--font-monospace);
  font-size: clamp(3.2rem, 8vw + 1rem, 4rem);
  font-weight: normal;
  color: var(--color-dark-grey);
}

.Intro__subheading {
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 1rem;
}

/* transform the grid layer to give a 3d effect */
.Intro__matrix-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.matrix__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* perspective is how high away we are from the item */
  perspective: var(--matrix-perpective);
}
.matrix-container--top {
  /* border: 1px dotted red; */
  transform: translateY(calc(var(--matrix-space-between-grids) * -1));
}
.matrix-container--bottom {
  /* border: 1px dotted blue; */
  transform: translateY(calc(var(--matrix-space-between-grids) * 1));
}
/* transform the grid layer to give a 3d effect */
.matrix {
  width: 100%;
  border-collapse: collapse;
}

/* rotate on the x access to flip container "head over heels" */
.matrix__top {
  transform: rotateX(calc(var(--matrix-grid-x-rotation) * -1));
}
.matrix__bottom {
  transform: rotateX(calc(var(--matrix-grid-x-rotation) * 1));
}
@keyframes grid-glow-color-change {
  0% {
    box-shadow: 0 0 1.8vw 0 var(--section-glow-color-1);
  }
  25% {
    box-shadow: 0 0 1.8vw 0 var(--section-glow-color-2);
  }
  50% {
    box-shadow: 0 0 1.8vw 0 var(--section-glow-color-3);
  }
  75% {
    box-shadow: 0 0 1.8vw 0 var(--section-glow-color-4);
  }
  100% {
    box-shadow: 0 0 1.8vw 0 var(--section-glow-color-1);
  }
}
.matrix td {
  aspect-ratio: 1/1;
  border: 1px solid var(--grid-border-color);
  box-shadow: 0 0 1.8vw 0 var(--grid-border-glow-color);
  /* width: min(30px, 125px); */
  animation: grid-glow-color-change 5s alternate infinite;
}
.Intro__matrix-container--lights-off {
  opacity: 0;
  transition: opacity var(--lights-off-speed-body) ease-out;
}
